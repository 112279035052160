<template>
  <section class="space-y-10">
    <QuickInfo />

    <asom-alert v-if="error" variant="error" :error-message="error" />

    <article v-if="canInteractWithCashBox">
      <h3 class="subheader">
        Quick Links
      </h3>
      <br />
      <section class="relative z-0  rounded-md grid sm:grid-cols-1 md:grid-cols-3">
        <asom-button
          variant="primary"
          outline
          rounded="none"
          @click="$router.push('cashbox/new')"
          icon="swap"
          text="New Cashbox"
        >
        </asom-button>
        <asom-button
          variant="primary"
          outline
          rounded="none"
          @click="$router.push('cashbox/movement')"
          icon="swap"
          text="Move Cashbox"
        >
        </asom-button>
      </section>
    </article>

    <div class="shadow overflow-auto border-b border-gray-200 sm:rounded-lg">
      <table class="min-w-full divide-y divide-gray-200">
        <caption class="hidden"></caption>
        <thead class="bg-gray-50">
          <tr>
            <th
              scope="col"
              class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              GTM/TUK Cashbox
            </th>
            <th
              scope="col"
              class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              <p class="flex items-center space-x-2">
                <asom-icon :icon="'dollar'" size="sm" />
                <span>AFC Notebox</span>
              </p>
            </th>
            <th
              scope="col"
              class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider flex items-center"
            >
              <p class="flex items-center space-x-2">
                <asom-icon :icon="'shape-circle'" size="sm" />
                <span>AFC Coinbox</span>
              </p>
            </th>
          </tr>
        </thead>
        <tbody class="bg-white divide-y divide-gray-200 text-sm">
          <tr>
            <td class="px-6 py-4 whitespace-nowrap">
              <template v-for="box in gtmNoteBoxes" :key="box.cashBoxId">
                <p class="flex items-center space-x-2">
                  <asom-icon :icon="'dollar'" size="sm" />
                  <span>{{ box.cashBoxSerialNo }}</span>
                </p>
              </template>
              <template v-for="box in gtmCoinBoxes" :key="box.cashBoxId">
                <p class="flex items-center space-x-2">
                  <asom-icon :icon="'shape-circle'" size="sm" />
                  <span>{{ box.cashBoxSerialNo }}</span>
                </p>
              </template>
            </td>
            <td class="px-6 py-4 whitespace-nowrap align-top">
              <article class="grid sm:grid-cols-1 md:grid-cols-2 gap-2">
                <aside>
                  <label class="font-bold">Pending Collection</label>
                  <template
                    v-for="box in afcFullNoteBoxes"
                    :key="box.cashBoxId"
                  >
                    <p>{{ box.cashBoxSerialNo }}</p>
                  </template>
                </aside>
                <aside>
                  <label class="font-bold">Empty</label>
                  <div class="table">
                    <div class="flex flex-row gap-2"
                      v-for="box in afcEmptyNoteBoxes"
                      :key="box.cashBoxId"
                    >
                      <div class="flex-1">
                        {{ box.cashBoxSerialNo }}
                      </div>
                      <button 
                        @click="onDeleteClicked(box)">
                        <asom-icon icon="trash" class="text-button align-text-bottom" size="sm" />
                      </button>
                    </div>
                  </div>
                </aside>
              </article>
            </td>
            <td class="px-6 py-4 whitespace-nowrap align-top">
              <article class="grid sm:grid-cols-1 md:grid-cols-2 gap-2">
                <aside>
                  <label class="font-bold">Pending Collection</label>
                  <template
                    v-for="box in afcFullCoinBoxes"
                    :key="box.cashBoxId"
                  >
                    <p>{{ box.cashBoxSerialNo }}</p>
                  </template>
                </aside>
                <aside>
                  <label class="font-bold">Empty</label>
                  <div class="table">
                    <div class="flex flex-row gap-2"
                      v-for="box in afcEmptyCoinBoxes"
                      :key="box.cashBoxId"
                    >
                      <div class="flex-1">
                        {{ box.cashBoxSerialNo }}
                      </div>
                      <button 
                        @click="onDeleteClicked(box)">
                        <asom-icon icon="trash" class="text-button align-text-bottom" size="sm" />
                      </button>
                    </div>
                  </div>
                </aside>
              </article>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- filter begin-->
    <asom-card title="Transaction Log Search">
      <div class="grid sm:grid-cols-1 md:grid-cols-2 md:gap-x-6 gap-4">
        <asom-form-field
          label="Date Created"
          required
          :state="inputStates('filters.dateRange')"
        >
          <asom-input-date-range
            v-model="filters.dateRange"
            datepicker-placement="bottom"
            :state="inputStates('filters.dateRange')"
          />
        </asom-form-field>
      </div>
      <template #footer>
        <asom-button
          text="Reset Filters"
          variant="secondary"
          @click="resetFilters"
        />
        <asom-button text="Apply" @click="loadData" />
      </template>
    </asom-card>
    <!-- filter end-->
    <article>
      <h3 class="subheader">
        Cash Box Transaction Log
      </h3>
      <br />
      <asom-client-table
        :columns="[
          'transactionNo',
          'date',
          'cashBoxType',
          'cashBoxSerialNumber',
          'location',
          'cashBoxStatus',
          'remarks',
          'documentReference',
          'createdBy',
        ]"
        :sortableColumns="[
          'transactionNo',
          'date',
          'cashBoxType',
          'cashBoxSerialNumber',
          'location',
          'cashBoxStatus',
          'remarks',
          'documentReference',
          'createdBy',
        ]"
        :searchableDateColumns="['date']"
        :data="summary.cashBoxTransactions"
      >
        <template v-slot:header_cashBoxStatus>Status</template>
        <template v-slot:header_createdBy>Staff</template>
        <template v-slot:header_cashBoxSerialNumber>Box Id</template>\
        <template v-slot:date="slotScoped">{{
          displayUtcDate(slotScoped.data)
        }}</template>
      </asom-client-table>
    </article>

    <article>
      <h3 class="subheader">
        Activities
      </h3>
      <br />
      <activity-log-list :data="summary.activityLogs" />
    </article>
  </section>

  <asom-modal title="Confirm" v-model="showModal">
    <asom-alert v-if="modalError" variant="error" :error-message="modalError" />
    <p class="pt-4">Are you sure you would like to delete cash box {{cashBoxToDelete.cashBoxSerialNo}} ?</p>
    <asom-input-textarea
      type="text"
      v-model="remarks"
      :state="inputStates('remarks')"
      :maxlength="1000"
    />
    <div class="flex flex-row-reverse pt-4">
      <div class="pl-4">
        <asom-button
          @click="confirmDeleteCashBox"
          :disabled="isSubmitting"
          :loading="isSubmitting"
          text="OK"
          variant="error" 
        />
      </div>
      <div>
        <asom-button @click="onCloseModal" text="Cancel" variant="secondary" />
      </div>
    </div>
  </asom-modal>
</template>

<script>
import get from "lodash.get";
import orderBy from 'lodash.orderby';
import { mapGetters } from "vuex";
import { getCashBoxSummary } from "@/services/cashManagement.service";
import { deleteCashBox } from "@/services/cashManagement.service";
import { displayUtcDate } from "@/helpers/dateTimeHelpers";
import QuickInfo from "../../_QuickInfo.vue";
import ActivityLogList from "../../_ActivityLogList.vue";
import inputStates from "@/mixins/inputStates";
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import moment from "moment";
import {toMoment} from "@/helpers/dateTimeHelpers";
export default {
  components: {
    QuickInfo,
    ActivityLogList,
  },
  mixins: [inputStates],
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      isLoading: false,
      error: null,
      modalError: null,
      showModal: false,
      isSubmitting: false,
      cashBoxToDelete: null,
      remarks: "",
      summary: {
        gtmCashBoxes: [],
        afcCoinBoxes: [],
        afcNoteBoxes: [],
        cashBoxTransactions: [],
        activityLogs: [],
      },
      filters: {
        dateRange: {
          start: moment().subtract(1, "months").toDate(),
          end: moment().toDate(),
        }
      }
    };
  },
  validations() {
    return {
      remarks: {
        required,
      },
    };
  },
  computed: {
    ...mapGetters({
      afcId: "cashManagement/stationCashAccounts/afcId",
      rosterPlanDate: "currentRoster/currentRosterPlanDate",
      rosterDws: "currentRoster/currentRosterShift",
      canInteractWithCashBox: 'auth/canInteractWithCashBox',
    }),
    gtmNoteBoxes() {
      return this.summary.gtmCashBoxes.filter(
        ({ cashBoxType }) => cashBoxType === "Note Box"
      );
    },
    gtmCoinBoxes() {
      return this.summary.gtmCashBoxes.filter(
        ({ cashBoxType }) => cashBoxType === "Coin Box"
      );
    },
    afcFullNoteBoxes() {
      return this.summary.afcNoteBoxes.filter(
        ({ cashBoxStatusEnum }) => cashBoxStatusEnum === 3
      );
    },
    afcFullCoinBoxes() {
      return this.summary.afcCoinBoxes.filter(
        ({ cashBoxStatusEnum }) => cashBoxStatusEnum === 3
      );
    },
    afcEmptyNoteBoxes() {
      return this.summary.afcNoteBoxes.filter(
        ({ cashBoxStatusEnum }) => cashBoxStatusEnum === 1
      );
    },
    afcEmptyCoinBoxes() {
      return this.summary.afcCoinBoxes.filter(
        ({ cashBoxStatusEnum }) => cashBoxStatusEnum === 1
      );
    },
    queryParams() {
      let params = {};
      if (
        this.filters.dateRange &&
        this.filters.dateRange.start &&
        this.filters.dateRange.end
      ) {
        params["searchDateFrom"] = this.parseDateTimeUTC(
          this.filters.dateRange.start,
          true
        );
        params["searchDateTo"] = this.parseDateTimeUTC(
          this.filters.dateRange.end,
          false
        );
      }
      return params;
    }
  },
  mounted() {
    this.loadData();
  },
  watch: {
    showModal: function(){
      this.v$.$reset();
      this.modalError = null;
    }
  },
  methods: {
    displayUtcDate,
    resetFilters() {
      this.filters.dateRange = null;
    },
    async loadData() {
      this.error = null;
      this.isLoading = true;
      await this.$store.dispatch('cashManagement/loadStationCashAccounts');
      var queryParams = {
        aFCId: this.afcId,
        ...this.queryParams,
      }
      const result = await getCashBoxSummary(queryParams);
      if (result.success) {
        this.setData(result);
      } else {
        this.error = result.payload;
        this.$scrollTop();
      }
      this.isLoading = false;
    },
    setData(apiResult) {
      this.summary.gtmCashBoxes = get(
        apiResult,
        "payload.data.gtmCashBoxes",
        []
      );
      this.summary.afcNoteBoxes = get(
        apiResult,
        "payload.data.afcNoteBoxes",
        []
      );
      this.summary.afcCoinBoxes = get(
        apiResult,
        "payload.data.afcCoinBoxes",
        []
      );
      this.summary.cashBoxTransactions = orderBy(
        get(
          apiResult,
          "payload.data.cashBoxTransactions",
          []
        ),
        'transactionNo',
        'desc',
      );
      this.summary.activityLogs = get(
        apiResult,
        "payload.data.activityLogs",
        []
      );
    },
    async confirmDeleteCashBox(){
      this.isSubmitting = true;
      this.error = null;
      this.v$.$reset();
      this.v$.$touch();

      if (!this.v$.$invalid) {
        const formData = {
        CashBoxId: this.cashBoxToDelete.cashBoxId,
        AFCId: this.afcId,
        CashBoxSerialNo: this.cashBoxToDelete.cashBoxSerialNo,
        remarks: this.remarks,
        rosterDWS: this.rosterDws,
        rosterPlanDate: this.rosterPlanDate,
        };
        const result = await deleteCashBox(formData);
        this.isSubmitting = false;
        this.showModal = false;
        if (!result.success) {
          this.error = result.payload;
          this.showModal = false;
          this.$scrollTop();
        } else {
          this.loadData();
        }
      } else {
        this.isSubmitting = false;
        this.modalError = "Please complete all required fields";
        this.$scrollTop();
      }
    },
    onDeleteClicked(cashBox) {
      this.cashBoxToDelete = cashBox;
      this.showModal = true;
    },
    onCloseModal() {
      this.v$.$reset();
      this.showModal = false;
      this.modalError = null;
    },
    parseDateTimeUTC(datetimeValue, isStartDate) {
      var d = toMoment(datetimeValue);
      if (d.isValid()) {
        var _d = isStartDate ? d.startOf("day") : d.endOf("day");
        const UTC_d = moment.utc(_d);
        return UTC_d.format("YYYY-MM-DD") + "T" + UTC_d.format("HH:mm:ss");
      }
      return "";
    },
  },
};
</script>
